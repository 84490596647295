// import xTable from './x-table/x-table'
// import upCover from './upfile/upCover'
// import upFiles from './upfile/upFiles'
import latLng from "./latLng/latLng"; // import upList from './upfile/upList'
// import upVideo from './upfile/upVideo'

export default {
  install: function install(v) {
    // v.component('xTable', xTable)
    // v.component('upCover', upCover)
    // v.component('upFiles', upFiles)
    v.component('latLng', latLng); // v.component('upList', upList)
    // v.component('upVideo', upVideo)
  }
};