import _objectSpread from "/home/runner/work/utopia-management/utopia-management/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import "/home/runner/work/utopia-management/utopia-management/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/runner/work/utopia-management/utopia-management/node_modules/core-js/modules/es6.promise.js";
import "/home/runner/work/utopia-management/utopia-management/node_modules/core-js/modules/es6.object.assign.js";
import "/home/runner/work/utopia-management/utopia-management/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css'; // import tableHeight from '@/directive/index.js'
// // 表格自适应指令
// Vue.use(tableHeight)

import "@/directive/index.js";
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
});
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css'; // 引入自定义组件

import u from '@/components/use';
Vue.use(u); // canvas

import VueCanvasPoster from 'vue-canvas-poster';
Vue.use(VueCanvasPoster); // echarts-图表

import VCharts from 'v-charts';
Vue.use(VCharts); // 数据字典

import dict from "./components/Dict"; // Vue+Element UI实现复制内容

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard); // 权限指令

import permission from "./components/Permission";
import "./assets/styles/element-variables.scss"; // global css

import "./assets/styles/index.scss"; // 代码高亮

import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon

import "./router/index"; // permission control

import 'echarts-gl';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict); // 富文本

import jQuery from 'jquery';
import VueFroala from 'vue-froala-wysiwyg';

require('froala-editor/js/froala_editor.pkgd.min');

require('froala-editor/css/froala_editor.pkgd.min.css');

require('font-awesome/css/font-awesome.css');

require('froala-editor/js/languages/zh_cn');

require('froala-editor/css/froala_style.min.css');

window.$ = jQuery;
window.jQuery = jQuery;
Vue.use(VueFroala); // 富文本

Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size

});
Vue.config.productionTip = false; // 中英文切换

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementLocale from 'element-ui/lib/locale';
import en from "./lang/en";
var messages = {
  en: _objectSpread(_objectSpread({}, en), enLocale),
  zh: _objectSpread({}, zhLocale)
}; // Create VueI18n instance with options

var i18n = new VueI18n({
  locale: 'en',
  // set locale
  messages: messages // set locale messages

});
ElementLocale.i18n(function (key, value) {
  return i18n.t(key, value);
}); // import enLocale from 'element-ui/lib/locale/lang/en'
// import en from './lang/en'
// // import en from 'element-ui/lib/locale/lang/en'
// const messagesI18n = {
//   en: {
//     ...en,
//     ...enLocale
//   }
// }
// const i18n = new VueI18n({
//   locale: 'en',
//   messages: messagesI18n
// })
// Vue.use(Element, {
//   i18n: (key, value) => i18n.t(key, value)
// })
// 正式版本-------
// const i18n = new VueI18n({
//   locale: 'en', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
//   messages: {
//     'zh': require('./lang/zh'), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
//     'en': require('./lang/en')
//   }
// })
// Vue.use(Element, {
//   i18n: (key, value) => i18n.t(key, value)
// })
// 正式版本-------

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyB4nhM1NcCHo2ziz3bIuBOXW2QJ4BXAEpA',
    key: 'AIzaSyCLN8bKW0SQuW7BmBmetXR1LzL71tPrPdw',
    libraries: 'places'
  }
});
new Vue({
  i18n: i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');