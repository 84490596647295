import Date from "./datetime.js";
export var calendarBaseShortcuts = [{
  text: 'Today',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: 'Yesterday',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().daysAgo(1).setHours(0, 0, 0));
    var endTime = new Date(new Date().daysAgo(1).setHours(23, 59, 59));
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: 'Last week',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().daysAgo(7).setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: 'Last month',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().daysAgo(30).setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: 'This month',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().monthBegin().setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: 'This quarter',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().quarterBegin().setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));
    picker.$emit('pick', [startTime, endTime]);
  }
}];
export var calendarMoveShortcuts = [{
  text: '‹ Previous day ',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().daysAgo(1).setHours(0, 0, 0));
    var endTime = new Date(new Date().daysAgo(1).setHours(23, 59, 59));

    if (!picker.value) {
      picker.value = [startTime, endTime];
    }

    startTime = picker.value[0].daysAgo(1);
    endTime = picker.value[1].daysAgo(1);
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: ' Next day ›',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));

    if (!picker.value) {
      picker.value = [startTime, endTime];
    }

    startTime = picker.value[0].daysAgo(-1);
    endTime = picker.value[1].daysAgo(-1);
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: '« Previous week ',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));

    if (!picker.value) {
      picker.value = [startTime.daysAgo(new Date().getDay()), endTime.daysAgo(new Date().getDay() + 1)];
    } else {
      picker.value = [picker.value[0].daysAgo(picker.value[0].getDay()), picker.value[1].daysAgo(picker.value[1].getDay() + 1)];
    }

    startTime = picker.value[0].daysAgo(7);
    endTime = picker.value[1];
    picker.$emit('pick', [startTime, endTime]);
  }
}, {
  text: ' Next week »',
  onClick: function onClick(picker) {
    var startTime = new Date(new Date().setHours(0, 0, 0));
    var endTime = new Date(new Date().setHours(23, 59, 59));

    if (!picker.value) {
      picker.value = [startTime.daysAgo(new Date().getDay() - 7), endTime.daysAgo(new Date().getDay() - 6)];
    } else {
      picker.value = [picker.value[0].daysAgo(picker.value[0].getDay() - 7), picker.value[1].daysAgo(picker.value[1].getDay() - 6)];
    }

    startTime = picker.value[0];
    endTime = picker.value[1].daysAgo(-7);
    picker.$emit('pick', [startTime, endTime]);
  }
}];
export var calendarShortcuts = [].concat(calendarBaseShortcuts, calendarMoveShortcuts);