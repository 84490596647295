import variables from '@/assets/styles/element-variables.scss';
import commonConstant from '@/utils/commonConstant';
var USER_AUDIT_STATUS = commonConstant.USER_AUDIT_STATUS,
    OFFER_STATUS = commonConstant.OFFER_STATUS,
    JOB_INVITE_STATUS = commonConstant.JOB_INVITE_STATUS,
    JOB_BOOK_STATUS = commonConstant.JOB_BOOK_STATUS,
    EDIT_NOTES_TYPE = commonConstant.EDIT_NOTES_TYPE;
var state = {
  theme: variables.theme,
  userAuditStatus: USER_AUDIT_STATUS,
  offerStatus: OFFER_STATUS,
  jobInviteStatus: JOB_INVITE_STATUS,
  jobBookStatus: JOB_BOOK_STATUS,
  editNotesType: EDIT_NOTES_TYPE
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};