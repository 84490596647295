import Vue from 'vue'; // 高度自适应

Vue.directive('setHeight', {
  inserted: function inserted(el, binding, vnode) {
    // const bottom = binding.value || 0
    var bottom = binding.value || 80;
    if (bottom === 'noCal') return;
    setTimeout(function () {
      el.style.height = getHeight(el) - bottom + 'px';
      el.setAttribute('data-layout', 'height');
      el.setAttribute('data-layout-bottom', bottom);
    }, 200);
  }
}); // 根据屏幕高度获取自身高度

var getHeight = function getHeight(el) {
  var winH = window.innerHeight;
  var top = el.getBoundingClientRect().top; // 6 为滚动条宽度

  return winH - top - 6;
};