export var baseRouterUrl = '/management/v1'; // 用户

export var baseModernAgeMenuUrl = '/management/v1/admin'; // 角色

export var baseModernAgePlatformUrl = '/management/v1/platform'; // export const baseRouterUrl = '/management/v1'baseFileModelmUrl
// 公开上传文件接口

export var basePublicFileModelmUrl = '/management/v1/file/upload/public/file'; // 私密文件上传接口

export var basePrivateFileModelmUrl = '/management/v1/file/upload/private/file'; // 富文本编辑器文件上传接口

export var baseEditorFileModelmUrl = '/management/v1/file/upload/rich/text/file'; // 文件显示路由
// export const baseFileModelUrl = 'https://utopia-dev-public.s3.ap-northeast-1.amazonaws.com/'

export var baseFileModelUrl = 'https://utopia-public-prod.s3.ap-southeast-2.amazonaws.com/';
export var baseBlmUrl = '/management/v1/hwyShop';
export var baseSsoUrl = '/management/v1/hwyShop';
export var baseItemUrl = '/management/v1/hwyShop'; // 上传证书
// export const baseFileModelUrl = '/management/v1/hwyShop/file/upload/credential'

export var baseAliyuncsProgramModelmUrl = 'https://jiuyin-program-version.oss-cn-hangzhou.aliyuncs.com/';