import request from '@/utils/request';
import { baseRouterUrl } from '@/api/config';
export function getAllCitys(data) {
  return request({
    url: "".concat(baseRouterUrl, "/centreFeeSchedule/list"),
    method: 'post',
    data: data
  });
}
export function add(data) {
  return request({
    url: "".concat(baseRouterUrl, "/centreFeeSchedule/create"),
    method: 'post',
    data: data
  });
}
export function del(data) {
  return request({
    url: "".concat(baseRouterUrl, "/centreFeeSchedule/delete"),
    method: 'post',
    data: data
  });
}
export function edit(data) {
  return request({
    url: "".concat(baseRouterUrl, "/centreFeeSchedule/update"),
    method: 'post',
    data: data
  });
}
export function setRegionAutoSwitch(data) {
  return request({
    url: "".concat(baseRouterUrl, "/centreFeeSchedule/update/auto/talent"),
    method: 'post',
    data: data
  });
}
export default {
  getAllCitys: getAllCitys,
  add: add,
  edit: edit,
  del: del,
  setRegionAutoSwitch: setRegionAutoSwitch
};