//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ThemePicker from '@/components/ThemePicker';
export default {
  components: {
    ThemePicker: ThemePicker
  },
  data: function data() {
    return {};
  },
  computed: {
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    },
    uniqueOpened: {
      get: function get() {
        return this.$store.state.settings.uniqueOpened;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'uniqueOpened',
          value: val
        });
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
    }
  }
};