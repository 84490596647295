// , getInfo
import { login, logout } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { getUser, setUser, removeUser } from '@/utils/user';
import Avatar from '@/assets/images/avatar.png';
var data = {
  state: {
    token: getToken(),
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      if (state.user.roleName === 'Super Admin') {
        roles.push('admin');
      }

      state.roles = roles;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        var _data = {
          userName: userInfo.userName,
          password: userInfo.password,
          captcha: userInfo.captcha,
          uuid: userInfo.uuid,
          systemType: userInfo.systemType
        };
        login(_data).then(function (res) {
          // console.log('user.login.res--->')
          // console.log(JSON.stringify(res))
          if (res.rtn_code === 0) {
            var rtn_data = res.rtn_data;
            setToken(rtn_data.token, rememberMe);
            commit('SET_TOKEN', rtn_data.token); // console.log(JSON.stringify(rtn_data.user))
            // 构造登录返回数据

            var user = {
              user: {
                // avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80',
                avatar: Avatar,
                phone: rtn_data.user.tel,
                email: rtn_data.user.email,
                userName: rtn_data.user.userName,
                realName: rtn_data.user.nickName,
                level: rtn_data.user.level,
                roleId: rtn_data.user.roleId,
                roleName: rtn_data.user.roleName,
                region: rtn_data.user.region,
                isAdmin: rtn_data.user.isAdmin
              },
              roles: ['admin']
            };
            setUserInfo(user, commit); // 第一次加载菜单时用到，具体见 src 目录下的 permission.js

            commit('SET_LOAD_MENUS', true);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(function (e) {
          reject(e);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        // 获取缓存
        var user_ = JSON.parse(getUser()); // console.log(user_)
        // 构造登录返回数据

        var user = {
          user: user_.user,
          roles: user_.roles
        };
        setUserInfo(user, commit);
        resolve(user);
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        logout().then(function (res) {
          logOut(commit);
          resolve(res);
        }).catch(function (e) {
          logOut(commit);
          reject(e);
        });
      });
    },
    setRoles: function setRoles(_ref4, roles) {
      var commit = _ref4.commit;
      commit('SET_ROLES', roles);
    },
    updateLoadMenus: function updateLoadMenus(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};
export var logOut = function logOut(commit) {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  removeToken();
  removeUser();
};
export var setUserInfo = function setUserInfo(res, commit) {
  // console.log(res)
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
  } else {
    commit('SET_ROLES', res.roles);
  }

  commit('SET_USER', res.user); // 缓存user

  setUser(res, true);
};
export default data;