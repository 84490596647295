import request from '@/utils/request'; // import { baseBlmUrl } from '@/api/config'

import { baseModernAgeMenuUrl } from '@/api/config';
export function getMenu(data) {
  return request({
    url: "".concat(baseModernAgeMenuUrl, "/menu/list"),
    method: 'post',
    data: data
  }); // var list = [
  //   {
  //     "name": "权限管理",
  //     "path": "/system",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "权限管理",
  //       "icon": "system",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "User",
  //         "path": "user",
  //         "hidden": false,
  //         "component": "system/user/index",
  //         "meta": {
  //           "title": "用户管理",
  //           "icon": "peoples",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Role",
  //         "path": "role",
  //         "hidden": false,
  //         "component": "system/role/index",
  //         "meta": {
  //           "title": "角色管理",
  //           "icon": "role",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Menu",
  //         "path": "menu",
  //         "hidden": false,
  //         "component": "system/menu/index",
  //         "meta": {
  //           "title": "菜单管理",
  //           "icon": "menu",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Dept",
  //         "path": "dept",
  //         "hidden": false,
  //         "component": "system/dept/index",
  //         "meta": {
  //           "title": "部门管理",
  //           "icon": "dept",
  //           "noCache": true
  //         }
  //       }
  //
  //     ]
  //   },
  //   {
  //     "name": "系统管理",
  //     "path": "/monitor",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "系统管理",
  //       "icon": "monitor",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "System",
  //         "path": "system",
  //         "hidden": false,
  //         "component": "monitor/system/index",
  //         "meta": {
  //           "title": "系统配置",
  //           "icon": "sqlMonitor",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Wechat",
  //         "path": "wechat",
  //         "hidden": false,
  //         "component": "monitor/wechat/index",
  //         "meta": {
  //           "title": "微信配置",
  //           "icon": "sqlMonitor",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Log",
  //         "path": "logs",
  //         "hidden": false,
  //         "component": "monitor/log/index",
  //         "meta": {
  //           "title": "操作日志",
  //           "icon": "log",
  //           "noCache": false
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "name": "会员管理",
  //     "path": "/member",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "会员管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "User",
  //         "path": "user",
  //         "hidden": false,
  //         "component": "member/user/index",
  //         "meta": {
  //           "title": "会员列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "level",
  //         "path": "level",
  //         "hidden": false,
  //         "component": "member/level/index",
  //         "meta": {
  //           "title": "会员级别",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "name": "商家管理",
  //     "path": "/store",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "商家管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "Shop",
  //         "path": "shop",
  //         "hidden": false,
  //         "component": "store/shop/index",
  //         "meta": {
  //           "title": "商家列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Stores",
  //         "path": "stores",
  //         "hidden": false,
  //         "component": "store/stores/index",
  //         "meta": {
  //           "title": "门店管理",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Coupon",
  //         "path": "coupon",
  //         "hidden": false,
  //         "component": "store/coupon/index",
  //         "meta": {
  //           "title": "优惠券列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Couponjl",
  //         "path": "couponjl",
  //         "hidden": false,
  //         "component": "store/couponjl/index",
  //         "meta": {
  //           "title": "优惠券记录",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Usersales",
  //         "path": "usersales",
  //         "hidden": true,
  //         "component": "store/usersales/index",
  //         "meta": {
  //           "title": "门店管理",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //     ]
  //   },
  //   {
  //     "name": "乐园管理",
  //     "path": "/venue",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "乐园管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "Park",
  //         "path": "park",
  //         "hidden": false,
  //         "component": "venue/park/index",
  //         "meta": {
  //           "title": "乐园列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Stadu",
  //         "path": "stadu",
  //         "hidden": false,
  //         "component": "venue/stadu/index",
  //         "meta": {
  //           "title": "场馆列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     'name': '门票管理',
  //     'path': '/ticket',
  //     'hidden': false,
  //     'redirect': 'noredirect',
  //     'component': 'Layout',
  //     'alwaysShow': true,
  //     'meta': {
  //       'title': '门票管理',
  //       'icon': 'menu',
  //       'noCache': true
  //     },
  //     'children': [
  //       {
  //         'name': 'cateList',
  //         'path': 'cateList',
  //         'hidden': true,
  //         'component': 'ticket/cateList/index',
  //         'meta': {
  //           'title': '门票类别',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'ticketList',
  //         'path': 'ticketList',
  //         'hidden': false,
  //         'component': 'ticket/ticketList/index',
  //         'meta': {
  //           'title': '门票列表',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'ticketPriceList',
  //         'path': 'ticketPriceList',
  //         'hidden': false,
  //         'component': 'ticket/ticketPriceList/index',
  //         'meta': {
  //           'title': '门票特惠列表',
  //           'noCache': true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     'name': '运营管理',
  //     'path': '/operate',
  //     'hidden': false,
  //     'redirect': 'noredirect',
  //     'component': 'Layout',
  //     'alwaysShow': true,
  //     'meta': {
  //       'title': '运营管理',
  //       'icon': 'menu',
  //       'noCache': true
  //     },
  //     'children': [
  //       {
  //         'name': 'category',
  //         'path': 'category',
  //         'hidden': false,
  //         'component': 'operate/category/index',
  //         'meta': {
  //           'title': '运营类别',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'bannerConfig',
  //         'path': 'bannerConfig',
  //         'hidden': false,
  //         'component': 'operate/bannerConfig/index',
  //         'meta': {
  //           'title': 'Banner配置',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'iconConfig',
  //         'path': 'iconConfig',
  //         'hidden': false,
  //         'component': 'operate/iconConfig/index',
  //         'meta': {
  //           'title': 'Icon配置',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'popupConfig',
  //         'path': 'popupConfig',
  //         'hidden': false,
  //         'component': 'operate/popupConfig/index',
  //         'meta': {
  //           'title': '弹窗配置',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'relation',
  //         'path': 'relation',
  //         'hidden': false,
  //         'component': 'operate/relation/index',
  //         'meta': {
  //           'title': '关联管理',
  //           'noCache': true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     'name': 'PK管理',
  //     'path': '/pk',
  //     'hidden': false,
  //     'redirect': 'noredirect',
  //     'component': 'Layout',
  //     'alwaysShow': true,
  //     'meta': {
  //       'title': 'PK管理',
  //       'icon': 'menu',
  //       'noCache': true
  //     },
  //     'children': [
  //       {
  //         'name': 'category',
  //         'path': 'category',
  //         'hidden': true,
  //         'component': 'pk/category/index',
  //         'meta': {
  //           'title': '类别信息',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'play',
  //         'path': 'play',
  //         'hidden': false,
  //         'component': 'pk/play/index',
  //         'meta': {
  //           'title': '场次管理',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'userTaskRecord',
  //         'path': 'userTaskRecord',
  //         'hidden': false,
  //         'component': 'pk/userTaskRecord/index',
  //         'meta': {
  //           'title': '记录管理',
  //           'noCache': true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     'name': '任务管理',
  //     'path': '/task',
  //     'hidden': false,
  //     'redirect': 'noredirect',
  //     'component': 'Layout',
  //     'alwaysShow': true,
  //     'meta': {
  //       'title': '任务管理',
  //       'icon': 'menu',
  //       'noCache': true
  //     },
  //     'children': [
  //       {
  //         'name': 'category',
  //         'path': 'category',
  //         'hidden': false,
  //         'component': 'task/category/index',
  //         'meta': {
  //           'title': '任务类别',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'taskDetail',
  //         'path': 'taskDetail',
  //         'hidden': false,
  //         'component': 'task/taskDetail/index',
  //         'meta': {
  //           'title': '任务管理',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'userTaskRecord',
  //         'path': 'userTaskRecord',
  //         'hidden': false,
  //         'component': 'task/userTaskRecord/index',
  //         'meta': {
  //           'title': '任务记录',
  //           'noCache': true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     'name': '奖励管理',
  //     'path': '/prize',
  //     'hidden': false,
  //     'redirect': 'noredirect',
  //     'component': 'Layout',
  //     'alwaysShow': true,
  //     'meta': {
  //       'title': '奖励管理',
  //       'icon': 'menu',
  //       'noCache': true
  //     },
  //     'children': [
  //       {
  //         'name': 'prizeList',
  //         'path': 'prizeList',
  //         'hidden': false,
  //         'component': 'prize/prizeList/index',
  //         'meta': {
  //           'title': '奖励列表',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'taskPrizeRelation',
  //         'path': 'taskPrizeRelation',
  //         'hidden': false,
  //         'component': 'prize/taskPrizeRelation/index',
  //         'meta': {
  //           'title': 'PK奖项配置',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'taskPrizeRelationTask',
  //         'path': 'taskPrizeRelationTask',
  //         'hidden': false,
  //         'component': 'prize/taskPrizeRelationTask/index',
  //         'meta': {
  //           'title': '任务奖项配置',
  //           'noCache': true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "name": "礼品商城",
  //     "path": "/giftstore",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "礼品商城管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "Cate",
  //         "path": "cate",
  //         "hidden": false,
  //         "component": "giftstore/cate/index",
  //         "meta": {
  //           "title": "类别管理",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Gift",
  //         "path": "gift",
  //         "hidden": false,
  //         "component": "giftstore/gift/index",
  //         "meta": {
  //           "title": "礼品管理",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Giftjl",
  //         "path": "giftjl",
  //         "hidden": false,
  //         "component": "giftstore/giftjl/index",
  //         "meta": {
  //           "title": "兑换记录",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "name": "内容管理",
  //     "path": "/content",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "内容管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "Cate",
  //         "path": "cate",
  //         "hidden": false,
  //         "component": "content/cata/index",
  //         "meta": {
  //           "title": "栏目管理",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Article",
  //         "path": "article",
  //         "hidden": false,
  //         "component": "content/article/index",
  //         "meta": {
  //           "title": "内容管理",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     'name': '全局统计',
  //     'path': '/globalCount',
  //     'hidden': false,
  //     'redirect': 'noredirect',
  //     'component': 'Layout',
  //     'alwaysShow': true,
  //     'meta': {
  //       'title': '全局统计',
  //       'icon': 'menu',
  //       'noCache': true
  //     },
  //     'children': [
  //       {
  //         'name': 'platAccountCount',
  //         'path': 'platAccountCount',
  //         'hidden': false,
  //         'component': 'globalCount/platAccountCount/index',
  //         'meta': {
  //           'title': '平台营业额统计',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'storeVerifyCount',
  //         'path': 'storeVerifyCount',
  //         'hidden': false,
  //         'component': 'globalCount/storeVerifyCount/index',
  //         'meta': {
  //           'title': '门店对账统计',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'userCount',
  //         'path': 'userCount',
  //         'hidden': false,
  //         'component': 'globalCount/userCount/index',
  //         'meta': {
  //           'title': '用户统计',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'accountCount',
  //         'path': 'accountCount',
  //         'hidden': false,
  //         'component': 'globalCount/accountCount/index',
  //         'meta': {
  //           'title': '流水统计',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'scoreCount',
  //         'path': 'scoreCount',
  //         'hidden': false,
  //         'component': 'globalCount/scoreCount/index',
  //         'meta': {
  //           'title': '盟币统计',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'ticketCount',
  //         'path': 'ticketCount',
  //         'hidden': false,
  //         'component': 'globalCount/ticketCount/index',
  //         'meta': {
  //           'title': '年票统计',
  //           'noCache': true
  //         }
  //       },
  //       {
  //         'name': 'appointCount',
  //         'path': 'appointCount',
  //         'hidden': false,
  //         'component': 'globalCount/appointCount/index',
  //         'meta': {
  //           'title': '预约统计',
  //           'noCache': true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "name": "订单管理",
  //     "path": "/order",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "订单管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "Orderjl",
  //         "path": "orderjl",
  //         "hidden": false,
  //         "component": "order/orderjl/index",
  //         "meta": {
  //           "title": "订单列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "OrderDetail",
  //         "path": "orderDetail",
  //         "hidden": true,
  //         "component": "order/orderjl/orderDetail",
  //         "meta": {
  //           "title": "订单详情",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //
  //     ]
  //   },
  //   {
  //     "name": "财务管理",
  //     "path": "/finance",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "财务管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "RechargeSet",
  //         "path": "rechargeSet",
  //         "hidden": false,
  //         "component": "finance/recharge/set",
  //         "meta": {
  //           "title": "充值设置",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Recharge",
  //         "path": "recharge",
  //         "hidden": false,
  //         "component": "finance/recharge/index",
  //         "meta": {
  //           "title": "充值记录",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "CashSet",
  //         "path": "cashSet",
  //         "hidden": false,
  //         "component": "finance/cash/set",
  //         "meta": {
  //           "title": "提现设置",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       },
  //       {
  //         "name": "Cash",
  //         "path": "cash",
  //         "hidden": false,
  //         "component": "finance/cash/index",
  //         "meta": {
  //           "title": "提现记录",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       }
  //     ]
  //   },
  //   {
  //     "name": "评价管理",
  //     "path": "/commont",
  //     "hidden": false,
  //     "redirect": "noredirect",
  //     "component": "Layout",
  //     "alwaysShow": true,
  //     "meta": {
  //       "title": "评价管理",
  //       "icon": "menu",
  //       "noCache": true
  //     },
  //     "children": [
  //       {
  //         "name": "Appra",
  //         "path": "appra",
  //         "hidden": false,
  //         "component": "commont/appra/index",
  //         "meta": {
  //           "title": "评价列表",
  //           "icon": "user",
  //           "noCache": true
  //         }
  //       }
  //     ]
  //   },
  // ]
  //
  // return list
}
export default {
  getMenu: getMenu
};