import Cookies from 'js-cookie';
import Config from '@/settings';
var UserKey = Config.UserKey;
export function getUser() {
  return Cookies.get(UserKey);
}
export function setUser(user, rememberMe) {
  if (rememberMe) {
    return Cookies.set(UserKey, user, {
      expires: Config.userCookieExpires
    });
  } else return Cookies.set(UserKey, user);
}
export function removeUser() {
  return Cookies.remove(UserKey);
}